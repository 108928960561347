<template>
  <div class="video-player">
    <video
      :src="src"
      :autoplay="autoplay"
      :controls="controls"
      :loop="loop"
      width="100%"
      height="100%"
      :poster="poster"
      :preload="preload"
      :playsinline="true"
      ref="player"
    />
    <div
      :class="`video-player__bg ${playing ? 'play' : 'pause'}`"
      :style="{ backgroundColor: bg }"
    ></div>
    <div class="video-player__controls">
      <button
        @click="togglePlay()"
        :class="`video-player__controls-toggleplay ${playing ? 'play' : 'pause'}`"
      >
        <IconPause v-if="playing" />
        <IconPlay v-else />
      </button>
    </div>
  </div>
</template>

<script>
const EVENTS = [
  "play",
  "pause",
  "ended",
  "loadeddata",
  "waiting",
  "playing",
  "timeupdate",
  "canplay",
  "canplaythrough",
  "statechanged",
];
import IconPlay from "@/components/icons/IconPlay.vue";
import IconPause from "@/components/icons/IconPause.vue";

export default {
  name: "Videoplayer",
  props: {
    src: { type: String, required: true },
    controls: { type: Boolean, required: false, default: false },
    loop: { type: Boolean, required: false, default: false },
    autoplay: { type: Boolean, required: false, default: false },
    poster: { type: String, required: false },
    preload: { type: String, required: false, default: "auto" },
    bg: { type: String, default: "" },
  },
  components: {
    IconPlay,
    IconPause,
  },
  data() {
    return {
      playing: false,
    };
  },
  mounted() {
    this.bindEvents();
  },
  methods: {
    bindEvents() {
      EVENTS.forEach(event => {
        this.bindVideoEvent(event);
      });
    },

    bindVideoEvent(which) {
      const player = this.$refs.player;

      player.addEventListener(
        which,
        event => {
          if (which === "loadeddata") {
            this.duration = player.duration;
          }

          if (which === "timeupdate") {
            this.percentagePlayed = (player.currentTime / player.duration) * 100;
          }

          this.$emit(which, { event, player: this });
        },
        true
      );
    },

    play() {
      this.$refs.player.play();
      this.setPlaying(true);
    },

    pause() {
      this.$refs.player.pause();
      this.setPlaying(false);
    },

    togglePlay() {
      if (this.playing) {
        this.pause();
      } else {
        this.play();
      }
    },

    setPlaying(state) {
      this.playing = state;
    },
  },
};
</script>
<style lang="scss" scoped>
.video-player {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 70vh;
  video{
    max-height: 70vh;
  }
  &__controls {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;

    transition: all 0.3s ease-in-out;
    z-index: 2;
    &-toggleplay {
      cursor: pointer;
    }
    .play {
      opacity: 0;
    }
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    &.play {
      opacity: 0;
    }
  }
  &:hover {
    .video-player__controls {
      .play {
        opacity: 1;
      }
    }
  }
}
</style>

