<template>
  <section class="art-advisory">
    <div class="art-advisory__container">
      <h2 class="art-advisory__title text-title">{{ artAdvisory.title }}</h2>
      <p class="art-advisory__description text-description">{{ artAdvisory.description }}</p>
      <BaseButton
        class="art-advisory__button button--white"
        text="INQUIRE"
        @click="$router.push(`/art-advisory/${slug}/inquire`)"
      ></BaseButton>
    </div>
    <div class="art-advisory__video" v-if="artAdvisory.videos && artAdvisory.videos.url">
      <CustomVideo :src="artAdvisory.videos.url" />
      <p v-if="artAdvisory.videos.caption">{{ artAdvisory.videos.caption }}</p>
    </div>
    <div class="carousel-wrapper">
      <IconButton
        v-if="artAdvisory.images.length > 1"
        class="carousel__button carousel__button--back button--circle button--white"
        @click="goToSlide('prev')"
      >
        <IconArrowTop class="carousel__button-icon carousel__button-icon"></IconArrowTop>
      </IconButton>
      <VueSlickCarousel
        v-bind="carouselSettings"
        ref="carousel"
        v-if="artAdvisory.images.length"
        @afterChange="changeSlide"
      >
        <div v-for="(slide, index) in artAdvisory.images" :key="index" class="slide">
          <img class="slide__image" :src="slide.url" :alt="slide.caption" />
        </div>
      </VueSlickCarousel>
      <IconButton
        v-if="artAdvisory.images.length > 1"
        class="carousel__button carousel__button--next button--circle button--white"
        @click="goToSlide('next')"
      >
        <IconArrowTop class="carousel__button-icon carousel__button-icon"></IconArrowTop>
      </IconButton>
    </div>
    <p class="slide__description text-description">{{ slideActive.caption }}</p>
  </section>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { mapMutations, mapActions, mapGetters } from "vuex";
import CustomVideo from "@/components/CustomVideo.vue";

export default {
  components: {
    BaseButton,
    IconButton,
    IconArrowTop,
    VueSlickCarousel,
    CustomVideo,
  },
  data() {
    return {
      carouselSettings: {
        centerMode: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        dots: false,
        infinite: true,
        centerPadding: "20px",
        variableWidth: true,
      },
      slug: this.$route.params.slug,
      slideActive: {},
    };
  },
  head: {
    title: function () {
      return {
        inner: this.artAdvisory.title,
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: this.artAdvisory.description,
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: `${this.artAdvisory.title} browngrotta arts`,
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: `${this.artAdvisory.title} browngrotta arts`,
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: `${this.artAdvisory.title} browngrotta arts`,
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: this.artAdvisory.description,
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: `${this.artAdvisory.title} browngrotta arts`,
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: this.artAdvisory.description,
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: `${this.artAdvisory.title} browngrotta arts`,
          id: "twitter:image:alt",
        },
      ];
    },
  },
  async created() {
    await this.apiArtAdvisory(this.slug);
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Art Advisory",
        to: "/art-advisory",
        clickable: true,
      },
      {
        title: this.artAdvisory.title,
        to: `/art-advisory/${this.slug}`,
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    this.slideActive = this.artAdvisory.images[0];
  },
  computed: {
    ...mapGetters("publicapi/services", {
      artAdvisory: "getArtAdvisory",
    }),
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publicapi/services", ["apiArtAdvisory"]),

    goToSlide(moveDirection) {
      let carousel = this.$refs.carousel;
      switch (moveDirection) {
        case "prev": {
          carousel.prev();
          break;
        }
        case "next": {
          carousel.next();
          break;
        }
      }
    },
    changeSlide(slideIndex) {
      this.slideActive = this.artAdvisory.images[slideIndex];
    },
  },
};
</script>

<style lang="scss" scoped>
.art-advisory {
  padding: 23rem 0;
  @media screen and (max-width: $xs) {
    padding: 24rem 0 11rem;
  }
  &__container {
    padding: 0 31.5rem 70px;
    @media screen and (max-width: $xs) {
      padding: 0 25px 4rem;
    }
  }
  &__title {
    font-size: 4rem;
    margin-bottom: 10px;
  }
  &__description {
    font-weight: 100;
    margin-bottom: 2.5rem;
  }
  &__button {
    min-width: 18rem;
  }
  &__video {
    padding: 0 13.7rem 70px;
    z-index: 2;
    @media screen and (max-width: $md) {
      padding: 0 8rem 7rem;
    }
    @media screen and (max-width: $sm) {
      padding: 0 3rem 4rem;
    }
    @media screen and (max-width: $xs) {
      padding: 0 25px 4rem;
    }
    p {
      font-size: 1.8rem;
      font-weight: 300;
      line-height: 1.66;
      color: $white;
      text-align: center;
      padding: 14px;
    }
  }
}

.carousel {
  &-wrapper {
    position: relative;
    margin-bottom: 10px;
  }
  &__button {
    width: 7rem;
    height: 7rem;
    @media screen and (max-width: $xs) {
      width: 4.2rem;
      height: 4.2rem;
    }
    &--next {
      position: absolute;
      right: 10.5rem;
      top: 50%;
      transform: rotate(90deg) translateY(-50%);
      z-index: 1;
    }
    &--back {
      position: absolute;
      left: 10.5rem;
      top: 50%;
      transform: rotate(-90deg) translateY(-50%);
      z-index: 1;
    }
    &-icon {
      width: 18px;
      height: 12px;
      @media screen and (max-width: $xs) {
        width: 14px;
        height: 8px;
      }
    }
  }
}
.slide {
  position: relative;
  margin: 0 5px;
  max-width: 60rem;
  max-height: 60rem;
  @media screen and (max-width: $xs) {
    max-width: 34.5rem;
    max-height: 34.5rem;
  }
  @media screen and (max-width: $xxxs) {
    max-width: 32.5rem;
    max-height: 32.5rem;
  }
  @media screen and (max-width: $xxxxs) {
    max-width: 27rem;
    max-height: 27rem;
  }
  &__description {
    width: 100%;
    font-weight: 100;
    text-align: center;
    margin-top: 15px;
    @media screen and (max-width: $xs) {
      padding: 0 25px;
    }
  }
}
</style>

